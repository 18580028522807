//$('.app_link').addClass('dropdown-item');
/* Slick logoslider */


/* Paperkit parallax */

window.onkeyup = function(e) {
  if (e.keyCode == 27) window.history.back();
}

$('.navbar-nav>li>a').on('click', function(){
    $('.navbar-collapse').collapse('hide');
});

var offset = 0;
$('.jsScrollTo').on('click',function(e) {
	e.preventDefault();
	var target = this.hash;
	if ($(this).data('offset') != undefined) offset = $(this).data('offset');
	$('html, body').stop().animate({
		'scrollTop': $(target).offset().top - offset
	}, 500, 'swing', function() {
		// window.location.hash = target;
	});
});

var big_image;

pk = {
    checkScrollForPresentationPage: debounce(function() {
    oVal = ($(window).scrollTop() / 3);
    big_image.css({
      'transform': 'translate3d(0,' + oVal + 'px,0)',
      '-webkit-transform': 'translate3d(0,' + oVal + 'px,0)',
      '-ms-transform': 'translate3d(0,' + oVal + 'px,0)',
      '-o-transform': 'translate3d(0,' + oVal + 'px,0)'
    });
  }, 4)
}
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};
function swerkCarousel(){
    window_width = $(window).width();
    if (window_width >= 768) {
    big_image = $('.page-header[data-parallax="true"]');

    if (big_image.length != 0) {
      $(window).on('scroll', pk.checkScrollForPresentationPage);
    }
  }

}

function save_run_js_function(function_name)
{
	if (typeof window[function_name] == 'function')
	{
		window[function_name]();
	}
}

/* End Paperkit parallax */


var markers = [];
function highlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  } else {
    markers[i].setAnimation(google.maps.Animation.BOUNCE);
  }
}
function stopHighlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  }
}
function initContactUsMap2(){
    var lCount;
    var sum1 = 0;
    var sum2 = 0;
    for (lCount = 0; lCount < locations.length; lCount++) {
        sum1 = sum1 + locations[lCount][1];
        sum2 = sum2 + locations[lCount][2];
    }
    var center1 = sum1 / lCount;
    var center2 = sum2 / lCount;

    //var myLatlng = new google.maps.LatLng(52.3898907, 4.9034652999999935);
    var myLatlng = new google.maps.LatLng(center1, center2);
    var mapOptions = {
            zoom: 14,
            center: myLatlng,
            styles: [
                {"featureType":"water","elementType":"geometry","stylers":[{"color":"#E6EEF8"},{"lightness":17}]},
                //{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#d5d5d5"},{"lightness":20}]},
                //{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},
                //{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},
                //{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},
                //{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},
                //{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},
                //{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},
                //{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},
                //{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},
                //{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},
                //{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},
                //{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},
                //{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}
            ],
            scrollwheel: false,
        }
    var map = new google.maps.Map(document.getElementById("contactUsMap2"), mapOptions);

    var infowindow = new google.maps.InfoWindow();

    var marker, i;
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            map: map
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }
    $(".notmap").hover(function() {
        highlightMarker($(this).data('value'));
        }, function() {
        stopHighlightMarker($(this).data('value'));
    });
}

function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}

$(document).ready(function(){
	
		AOS.init();
	
	    $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 2
            }
        }]
    });
	
    $('.truncate').succinct({
    size: 200
	});

    $('.parallax-window').parallax();
        	  
});
$(window).on("load", function (e) {
	
	save_run_js_function('swerkCarousel');
	
	add_js_input('#contact_form');
	
	$('.masonry-holder').masonry({ itemSelector: '.masonry-item' });
	
	var cw = $('.imageholder').width();
	$('.imageholder').css({'height':cw+'px'});

    if(window.validate_contact_form){
		validate_contact_form();
	}
    //demo.initContactUsMap2();

    cookieAkkoord = Cookies.get('cookieAkkoord');
    if(cookieAkkoord == undefined){
        $('.cookie').fadeIn(300);
    }
    $('.close_cookie').on('click',function(e){
        Cookies.set('cookieAkkoord', 'shown');
        $('.cookie').fadeOut(300);
    });
        if($('#contactUsMap2').length){
        initContactUsMap2();
    }
})
$(window).resize(function() {
	var cw = $('.imageholder').width();
$('.imageholder').css({'height':cw+'px'});
$('.masonry-holder').masonry({ itemSelector: '.blog-item-hp' });
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

